import { AxiosError } from 'axios';
import { apiAuth } from '../libs/utils/api';

const forgotPassword = async (email: string) => {
    try {
        await apiAuth.post('/users/forgot-password', {
            email,
        });
    } catch (error) {
        throw new Error('An error occured.');
    }
};

const verifyResetPasswordToken = async (token: string) => {
    try {
        const result = await apiAuth.get(`/users/password/verify-token/${token}`);
        return result.data;
    } catch (error) {
        const err = error as AxiosError<string>;
        if (err.response?.status === 404) {
            throw new Error('Oops! This password reset request has expired.');
        }
        throw new Error('An error occured');
    }
};

const resetPassword = async (payload: any) => {
    try {
        const { token, password, email } = payload;
        await apiAuth.put(`/users/reset-password/${token}`, {
            email,
            password,
        });
    } catch (error) {
        const err = error as AxiosError<any>;
        if (err.response?.status === 404) {
            throw new Error('Oops! This password reset request has expired.');
        } else if (err.response?.status === 400) {
            throw new Error(err.response.data.message);
        }
        throw new Error('An error occured');
    }
};

const fetchSettings = async (domain: string) => {
  try {
    const result = await apiAuth.get('/setting/hpp/resolve', { params: { domain } });
    return result.data;
  } catch (error) {
    throw new Error('An error occured');
  }
};

export const userService = {
    forgotPassword,
    verifyResetPasswordToken,
    resetPassword,
    fetchSettings,
};
