import { PaymentProviderConfigType } from './payment-provider-types';
import {
    voltConfigs, visaCardConfigs, tabbyConfigs, zipConfig, fiservCardsAuConfig, grabConfigs, tamaraConfig, crediteaConfig,
    hummConfigs,
    contactEgConfig,
    nuapayConfigs,
    paybyConfigs,
    trustlyConfigs,
    dlocalConfigs,
} from './payment-management-config';

export const paymentProviderConfigs: PaymentProviderConfigType = {
    VOLT: voltConfigs,
    VOLT_BR: voltConfigs,
    VOLT_AU: voltConfigs,
    VISA_CYBERSOURCE_CARD: visaCardConfigs,
    TABBY_AE: tabbyConfigs,
    TABBY_BH: tabbyConfigs,
    TABBY_KW: tabbyConfigs,
    TABBY_QA: tabbyConfigs,
    TABBY_SA: tabbyConfigs,
    ZIP_US: zipConfig,
    ZIP_UK: zipConfig,
    ZIP_NZ: zipConfig,
    ZIP_AU: zipConfig,
    ZIP_SG: zipConfig,
    ZIP_CA: zipConfig,
    FISERV_CARDS_AU: fiservCardsAuConfig,
    GRAB_SG: grabConfigs,
    GRAB_MY: grabConfigs,
    TAMARA_SA: tamaraConfig,
    TAMARA_AE: tamaraConfig,
    CREDITEA_MX: crediteaConfig,
    HUMM_CA: hummConfigs,
    CONTACT_EG: contactEgConfig,
    SOUHOOLA_EG: contactEgConfig,
    NUAPAY_GB: nuapayConfigs,
    NUAPAY_EU: nuapayConfigs,
    PAYBY_AE: paybyConfigs,
    TRUSTLY_EU: trustlyConfigs,
    TRUSTLY_NL: trustlyConfigs,
    DLOCAL_SN: dlocalConfigs,
    DLOCAL_CI: dlocalConfigs,
    DLOCAL_PAGA_WALLET_NG: dlocalConfigs,
    DLOCAL_OPAY_NG: dlocalConfigs,
};